<template>
  <div class="flex justify-end mb-6">
    <select
      class="block px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px] w-[200px]"
      required
      v-model="conversionCurrency"
      @change="getRate"
    >
      <option value="">Select default currency</option>
      <option value="NGN">Nigerian Naira</option>
      <option value="USD">United States Dollar</option>
    </select>
  </div>

  <div class="md:grid grid-cols-3 gap-10 pb-5">
    <subscription-card v-for="item in subscriptions" :key="item.uuid" :subscription="item" :rate="conversionRate" :show-features="true" :show-cta="true" />
  </div>

  <LoadingOverlay v-if="loading" />
</template>

<script setup>
import SubscriptionCard from '@/components/subscriptions/SubscriptionCard.vue';
import { useToast } from 'vue-toastification';
import { errorMessage } from '@/utils/helper';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import LoadingOverlay from '../LoadingOverlay.vue';

const toast = useToast();
const store = useStore();
const loading = ref(false);
const conversionRate = ref(1);
const conversionCurrency = ref('USD');

const subscriptions = computed(() => {
  return store.getters['global/getPlans'] || [];
});

const getRate = async () => {
  localStorage.setItem('currency', conversionCurrency.value);
  if (conversionCurrency.value == 'USD') {
    conversionRate.value = 1;
    return;
  }
  try {
    loading.value = true;
    const res = await store.dispatch('global/getConversionRate');
    if (res?.value > 0) {
      conversionRate.value = res.value;
    }
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await getRate();
});
</script>
